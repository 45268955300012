import {makeAutoObservable} from 'mobx';

class BaseStore {

    darkMode = false;

    constructor(rootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore;
    }

    toggleDarkMode() {
        this.darkMode = !this.darkMode
    }
}

export default BaseStore;