import React from 'react'

const DescriptionText = () => {

    return (
        <div className="flex flex-column align-items-start three-quarter-width padding-md description-text-para"
             style={{alignSelf: 'start'}}>
            I believe in clean coding and slick design. Building astonishing apps is my passion, hearing compliments is
            my pleasure. Users demand cool products to use instead of sloppy ones, so I work with that purpose.
        </div>
    );

}

export default DescriptionText