import React from "react";
import {IconButton} from "ui-neumorphism";

const CommunicationButton = (props) => {

    return (
        <div>
            <IconButton className="communication-buttons" dark={props.dark} onClick={() => {
                window.open(props.link, "_blank")
            }} text={false} size='large' color='var(--warning)'>
                <img className="communication-buttons-image" src={props.src} alt={props.alt}/>
            </IconButton>
        </div>
    )
}

export default CommunicationButton