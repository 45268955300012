import praveen from '.././images/praveen.png'
import abhitej from '.././images/abhitej.png'
import james from '.././images/james.png'
import saleem from '.././images/saleem.png'
import harjot from '.././images/harjot.png'
import tanmoy from '.././images/tanmoy.png'
import kshitij from '../images/kshitij.png'
import hitesh from '.././images/hitesh.png'
import olayiwola from '.././images/olayiwola.png'

export default [
    {
        name: "Praveen Kadakal",
        imageSrc: praveen,
        subtitle: "Product Manager, Walmart",
        review: "Sparsh was part of my dev team at Walmart Global Tech and we worked together in the Customer care team. He is a very passionate developer with great sense of commitment and accountability. He is always ready for new challenges and is very skilled at getting to the root of the problems and coming up with the best solutions. I had great fun working with him as part of the customer care team. He was one of the key members of the team that worked on launching Native help center and Chatbot automation solution for Walmart. He has all the necessary skill set and attitude that can take him to great heights in future. My best wishes for his future endeavours."
    },
    {
        name: "Abhitej Singh",
        imageSrc: abhitej,
        subtitle: "Strategy, Persistence",
        review: "Sparsh is a highly professional and talented individual. I really respect his work ethics and the energy he fuels into every project he works on. I have worked with Sparsh on multiple projects and can say without hesitation that he is one of the finest developers I have worked with. It is surprising sometimes to see that he lives for app development and brings out amazing practices always. If I have to hire an app developer Sparsh will be always my first choice"
    },
    {
        name: "James Risner",
        imageSrc: james,
        subtitle: "Freelance Client, USA",
        review: "Sparsh is a pretty awesome dude who is committed to making things right. I truly think he's going to go on to bigger and better things, so it's best to act now and get him while you can! This man is a godsend to people in need of app development."
    },
    {
        name: "Saleem Javid",
        imageSrc: saleem,
        subtitle: "Founder, SelectDine",
        review: "I rarely come across real talents who stand out like Sparsh. I had the pleasure of working with Sparsh. Sparsh’s ability to handle Projects was unlike any I’ve seen before and made a dramatic increase in the productivity level of our company. Sparsh earns my highest recommendation."
    },
    {
        name: "Harjot Singh Oberoi",
        imageSrc: harjot,
        subtitle: "Co-Founder, FamPay",
        review: "Sparsh joined FamPay in February as an intern in the android team. Sparsh got up to pace really quickly and started build standalone features within the first week itself. He has a very good grasp of android architecture and concepts, and even introduced us to a few tricks as well. From building complex UIs from API responses, to handling social SDKs, Sparsh worked on a wide spectrum of tasks. By getting involved in feature meetings and completely understanding the design process and specifications, he was able to complete tasks quickly and as per the requirements, without much iterations. He has a great passion for android development and strives to get better everyday."
    },
    {
        name: "Kshitij Nagvekar",
        imageSrc: kshitij,
        subtitle: "Co-Founder, WorkIndia",
        review: "Sparsh has following 4 qualities which I found to be amazing. Enjoyed working with him at WorkIndia." +
            "\n  1. Being Passionate - His passion for Android and technology keeps him focused and brings out the best work in him." +
            "\n  2. Being a learner - At such an early age, he knows things that experienced people don't. I think that's mainly because he learns thru real world experiences." +
            "\n  3. Being responsible and kind - Be it responsibility of app or team-mates, he has been great at it." +
            "\n  4. Being courageous - Courage gives his the tenacity to work through issues and disagreement without compromising on quality."
    },
    {
        name: "Tanmoy Bose",
        imageSrc: tanmoy,
        subtitle: "Freelance Client, India",
        review: "I interacted with Sparsh during the build of boseautoguide app (a B2B application in automobile domain) and was quite amazed by his sharp technical skill set and dedication! The best thing is that he will listen to your end objective and ideas and at the same time even recommend multiple options that may better suit your need - one of the key success mantra towards building a good client engagement. He will definitely be an asset to any any team that he works with."
    },
    {
        name: "Hitesh Bhardwaj",
        imageSrc: hitesh,
        subtitle: "Co-Founder, CapX",
        review: "Sparsh is, without doubt, one of the most enthusiastic and hardworking individual I have met. I got the opportunity to work with Sparsh while developing a COVID tracing application. Sparsh showed commendable dedication and commitment for the project. It has been a very pleasant experience working together."
    },
    {
        name: "Olayiwola",
        imageSrc: olayiwola,
        subtitle: "Freelance Client, Nigeria",
        review: "Sparsh is a programmer with human face, he has officially become my Technical Partner. I love his job so much. I recommend him to anyone whose goal is a very clean job. (sic)"
    }
]