import React, {useState} from "react";
import {inject} from "mobx-react";
import {Card} from "ui-neumorphism";
import 'ui-neumorphism/dist/index.css'

const TestimonialCard = (props) => {
    const [hover, setHover] = useState(true);
    return (
        <div className="flex flex-column space-between padding-md align-items-center" onMouseEnter={() => {
            setHover(false)
        }} onMouseLeave={() => {
            setHover(true)
        }}>

            <Card className="padding-md test-card" dark={props.baseStore.darkMode} inset={hover}>

                <div style={{display: 'flex'}}>
                    <div>
                        <Card style={{borderRadius: "50% 50%"}} className="flex align-items-center"
                              dark={props.baseStore.darkMode} rounded={true}>
                            <img src={props.imageSrc} alt={props.name} className="testimonial-avatar"/>
                        </Card>
                    </div>

                    <div className="flex flex-column align-items-start" style={{margin: "0px 0px 0px 8px"}}>
                        <div className="testimonial-name-text">{props.name}</div>
                        <div className="testimonial-subtitle-text">{props.subtitle}</div>
                    </div>
                </div>
                <div className="testimonial-review-text" style={{textAlign: "start"}}>
                    {props.review}
                </div>
            </Card>
        </div>
    );

}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(TestimonialCard);