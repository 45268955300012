import React from "react";
import {Divider} from "ui-neumorphism";
import {inject, Observer} from 'mobx-react'
import IntroductionText from "./IntroductionText";
import DescriptionText from "./DescriptionText";
import CommunicationChannels from "./CommunicationChannels";
import Header from "./Header";
import Footer from "./Footer";
import DarkModeButton from "./DarkModeButton";


const HomeComponent = (props) => {
    return (
        <Observer>
            {() => (
                <div className={`${props.baseStore.darkMode ? 'parent-dark' : 'parent'}`}>

                    <div className="app flex flex-column">


                        <div className="flex" style={{flex: 1}}>
                            <Header navigationText="Testimonials" navigationPath="/testimonials"/>
                        </div>

                        <div className="flex" style={{flex: 10}}>
                            <div className="flex-column"
                                 style={{flex: 10, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IntroductionText dark={props.baseStore.darkMode}/>
                                <Divider className="align-items-start" dense elevated dark={props.baseStore.darkMode}
                                         style={{width: '70%', alignSelf: 'start', margin: '0 16px 0 16px'}}/>
                                <DescriptionText dark={props.baseStore.darkMode}/>
                                <CommunicationChannels dark={props.baseStore.darkMode}/>
                            </div>
                        </div>

                        <div className="flex flex-column" style={{flex: 1}}>
                            <DarkModeButton/>
                            <Footer/>
                        </div>
                    </div>
                </div>
            )}</Observer>
    );
}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(HomeComponent);

