import React from "react";
import {inject} from "mobx-react";
import testimonials from '.././configs/Testimonials'
import TestimonialCard from "./TestimonialCard";
import StackGrid from "react-stack-grid";

const TestimonialContent = (props) => {

    return (
        <StackGrid
            style={{width: "100%"}}
            columnWidth={window.matchMedia("(max-width: 480px)").matches ? "100%" : "48%"}>
            {
                testimonials.map((item, key) => (
                    <div key={key}>
                        <TestimonialCard
                            key={key}
                            name={item.name}
                            imageSrc={item.imageSrc}
                            subtitle={item.subtitle}
                            review={item.review}/>

                    </div>
                ))
            }
        </StackGrid>
    );

}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(TestimonialContent);