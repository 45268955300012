import React from "react";
import HeaderContent from "./HeaderContent";
import {Divider} from "ui-neumorphism";
import {inject, Observer} from 'mobx-react'

const Header = (props) => {
    return (
        <Observer>
            {() => (
                <div style={{flex: 1}}>
                    <HeaderContent navigationText={props.navigationText} navigationPath={props.navigationPath} dark={props.baseStore.darkMode}/>
                    <Divider dark={props.baseStore.darkMode}/>
                </div>
            )}</Observer>
    );
}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(Header);

