let Navigator = null;

/**
 * Sets the Navigation
 * @param {Ref} ref
 *
 */
const setNavigator = (ref) => {
    Navigator = ref;
};

/**
 * Handle Navigation
 * Pushes path ro the history API
 * @param {String} path  path to be pushed to history API
 * @param {Object} [param={}]
 */
const push = (path = '', param = {}) => {
    if (typeof window.LoadStarted !== 'undefined') {
        window.LoadStarted = Date.now();
    }
    Navigator.history.push(path, param);
    // window.location = path;
};

/**
 * Handle Navigation
 * Replaces path ro the history API
 * @param {String} path  path to be pushed to history API
 * @param {Object} [param={}]
 */
const replace = (path = '', param = {}) => {
    Navigator.history.replace(path, param);
};

// const reset = (path = '') => {
//     // Navigator.history.replaceState(path);
//     // Navigator.history.push(path);
// }

/**
 * Returns Navigator
 * @return {History} returns navigator
 */
const getNavigation = () => {
    return Navigator;
};

export default {
    setNavigator,
    push,
    replace,
    getNavigation,
};
