import React from "react";
import light from "../images/millennium-falcon.png";
import dark from "../images/death-star.png";
import {Button, Card} from "ui-neumorphism";
import {inject} from "mobx-react";

const DarkModeButton = (props) => {

    return (
        <Button size={window.matchMedia("(max-width: 480px)").matches ? "medium" : "medium"}
                style={{margin: "24px 0px 16px 0px", alignSelf: "center"}}
                rounded
                dark={props.baseStore.darkMode} onClick={() => {
            props.baseStore.toggleDarkMode()
        }}>
            <Card style={{
                display: props.baseStore.darkMode ? "inherit" : "none",
                margin: "0px 8px 0px 0px",
                padding: "3px"
            }} elevation={4} inset rounded={true}>
                <img className="dark-mode-image" src={light} alt="light-mode"/>
            </Card>

            <div style={{textTransform: "capitalize", fontWeight: "normal"}}
                 className={`${props.baseStore.darkMode ? 'light-side' : 'dark-side'} description-text-dark-button`}>
                Switch to {props.baseStore.darkMode ? "Light" : "Dark"} Side
            </div>
            <Card style={{
                display: props.baseStore.darkMode ? "none" : "inherit",
                margin: "0px 0px 0px 8px",
                padding: "3px"
            }} elevation={4} inset rounded={true}>
                <img className="dark-mode-image" src={dark} alt="dark-mode"/>
            </Card>
        </Button>
    );

}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(DarkModeButton);