import React from "react";
import '../App.css';
import {Button, Card} from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import avatar from '.././images/sparsh-avatar.png'
import {inject} from "mobx-react";
import Navigation from "../utils/Navigation";

const HeaderContent = (props) => {

    function formatPath(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div className="flex flex-row space-between padding-md align-items-center">

            <div className="flex align-items-center">
                <Card style={{borderRadius: "50% 50%"}} dark={props.baseStore.darkMode} rounded={true}>
                    <img onClick={() => {
                        Navigation.push('/home')
                    }} src={avatar} alt="Avatar" className="avatar"/>
                </Card>

                <div className="flex flex-row" style={{margin: '8px', opacity: '0.8'}}>
                    <div className="title-text">
                        Sparsh&nbsp;
                    </div>
                    <div className="title-text-path">
                        /&nbsp;{formatPath(window.location.pathname.slice(1))}
                    </div>
                </div>

            </div>

            <div className="flex flex-row space-between align-items-center">
                <Button size={window.matchMedia("(max-width: 480px)").matches ? "small" : "medium"} dark={props.dark}
                        onClick={() => {
                            Navigation.push(props.navigationPath)
                        }}>
                    <div className="description-text-para"
                         style={{textTransform: "capitalize", fontWeight: "normal"}}>{props.navigationText}
                    </div>
                </Button>
            </div>
        </div>
    );

}

export default inject(({rootStore}) => ({
    baseStore: rootStore.baseStore
}))(HeaderContent);
